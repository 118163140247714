import React from "react";
import useSWR from "swr";
import { OfferListItem } from "./offerListItem";
import { OfferListItemSkeleton } from "./offerListItemSkeleton";
import { OFFERS } from "../../utils/endpoints";
import { classNames, fetcher } from "../../utils/misc";
import { NoOffers } from "./noOffers";

export const CurrentPreviewList = ({ url, invert = false }) => {
  const { data, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });

  return (
    <div
      className={classNames(
        invert ? "md:bg-transparent" : "fel-blue-gradient pb-40",
        "section bg-white"
      )}
    >
      <div className="section-container">
        <h2
          className={classNames(
            invert ? "md:text-white" : "text-fel-purple",
            "section-title text-fel-purple"
          )}
        >
          Offres du moment
        </h2>
        <div className="section-list">
          {data && !error ? (
            url.search("limit") !== -1 ? (
              data.data.map((offer, index) => (
                <OfferListItem key={index} offer={offer} />
              ))
            ) : (
              data.map((offer, index) => (
                <OfferListItem key={index} offer={offer} />
              ))
            )
          ) : (
            <>
              {[...Array(4).keys()].map((index) => (
                <OfferListItemSkeleton key={index} />
              ))}
            </>
          )}
          {data && data.length < 1 && <NoOffers />}
        </div>
      </div>
    </div>
  );
};
