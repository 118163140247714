import { UpcomingPreviewList } from "../components/offer/upcomingPreviewList";
import { CurrentPreviewList } from "../components/offer/currentPreviewList";
import { PastPreviewList } from "../components/offer/pastPreviewList";
import WhatsThis from "../components/general/WhatsThis";
import SubmitOffer from "../components/general/SubmitOffer";
import { Layout } from "../components/layout";
import { fetcher } from "../utils/misc";
import { SWRConfig } from "swr";
import {
  CATEGORIES,
  INDICATORS,
  OFFERS,
  PAST,
  UPCOMING,
} from "../utils/endpoints";
import Meta from "../components/Meta";

export default function Home({ fallback }) {
  return (
    <SWRConfig value={{ fallback }}>
      <Layout isHome>
        <Meta title="Accueil" />
        <CurrentPreviewList url={OFFERS} invert={true} />
        <UpcomingPreviewList />
        <PastPreviewList />
        <WhatsThis />
        <SubmitOffer />
      </Layout>
    </SWRConfig>
  );
}

export async function getStaticProps() {
  return {
    props: {
      fallback: {
        [OFFERS]: await fetcher(OFFERS),
        [CATEGORIES]: await fetcher(CATEGORIES),
        [`${UPCOMING}?limit=6`]: await fetcher(`${UPCOMING}?limit=6`),
        [`${PAST}?limit=6`]: await fetcher(`${PAST}?limit=6`),
        [INDICATORS]: await fetcher(INDICATORS),
      },
    },
    revalidate: 600,
  };
}
